// import appsFlyer from 'react-native-appsflyer';
import clevertap from 'clevertap-web-sdk';
import ReactGA from 'react-ga4';

import config from '../config';

export const initAnalytics = () => {
  clevertap.init(config.clevertapAccountId, 'us1');
  clevertap.setLogLevel(3);
  ReactGA.initialize(config.googleAnalytics4);
};

export const initAppsFlyer = () => {
  // appsFlyer.initSdk(
  //   {
  //     devKey: config.appsflyerDevKey,
  //     isDebug: false,
  //     appId: config.appsflyerAppId,
  //     onInstallConversionDataListener: true,
  //     onDeepLinkListener: true,
  //   },
  //   (res) => {
  //     console.log('[APPSFLYER]', res);
  //   },
  //   (err) => {
  //     console.error('[APPSFLYER][ERROR]', err);
  //   },
  // );
  // appsFlyer.setAppInviteOneLinkID(config.appsflyerInviteLinkId);
};

export const onUserLogin = (properties: object) => {
  clevertap.onUserLogin.push({
    Site: {
      properties,
    },
  });
};

export const identifyOnSignUp = (identity: string, properties: object) => {
  clevertap.onUserLogin.push({
    Site: {
      ...properties,
    },
  });
};

export const track = (event: string) => {
  clevertap.event.push(event);
};

export const trackWithProperties = (event: string, properties: object) => {
  clevertap.event.push(event, properties);
};

export const setProfilePropertyCT = (properties: object) => {
  clevertap.onUserLogin.push({
    Site: {
      ...properties,
    },
  });
};

export const logEventAF = (event: string, properties: object = {}) => {
  // appsFlyer.logEvent(
  //   event,
  //   { ...properties },
  //   (res) => {
  //     console.log('[APPSFLYER]', res);
  //   },
  //   (err) => {
  //     console.error('[APPSFLYER][ERROR]', err);
  //   },
  // );
};

export const getAllDisplayUnits = () => {
  // clevertap.getAllDisplayUnits((err: any, res: any) => {
  //   console.log('All Display Units: ', res, err);
  // });
};

export const getAppsflyerId = async (store: any) => {
  // await appsFlyer.getAppsFlyerUID((error, uid) => {
  //   if (error) {
  //     console.error('[APPSFLYER][ERROR]', error);
  //   } else {
  //     console.log('[APPSFLYER] ID is ', uid);
  //     store.setAppsflyerId(uid);
  //   }
  // });
};
