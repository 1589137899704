import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import LogoUgami from '../../assets/images/Logo_ugami.svg';
import { Language } from '../../constants/Language';
import { useStore } from '../../stores';

const Navbar = () => {
  const styles = useStyleSheet(themedStyles);
  const { languageStore } = useStore();
  const isMobile = useMediaQuery({ maxWidth: 700 });

  const changeBtnColor = (btn, color) => {
    const esItem = document.getElementById('es');
    const enItem = document.getElementById('en');
    btn === 'es' ? (esItem.style.color = color) : (enItem.style.color = color);
  };
  return (
    <div style={styles.nav}>
      <div style={styles.polygon}>
        <div
          style={
            isMobile ? styles.logoUgamiBoxMobile : styles.logoUgamiBoxDesktop
          }
        >
          <img src={LogoUgami} style={styles.logoUgami} />
        </div>
        <div style={styles.languageSwitch}>
          <a
            onClick={() => languageStore.updateAppLanguage(Language.ENGLISH)}
            style={styles.languageButton}
          >
            <span
              id="en"
              onMouseOver={() => changeBtnColor('en', 'red')}
              onMouseOut={() => changeBtnColor('en', 'white')}
            >
              EN
            </span>
          </a>
          <a
            onClick={() => languageStore.updateAppLanguage(Language.SPANISH)}
            style={styles.languageButton}
          >
            <span
              id="es"
              onMouseOver={() => changeBtnColor('es', 'red')}
              onMouseOut={() => changeBtnColor('es', 'white')}
            >
              ES
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
const themedStyles = StyleService.create({
  nav: {
    height: '60px',
    width: '100%',
    background:
      'linear-gradient(90deg, rgba(87,0,0,1) 0%, rgba(221,25,0,1) 100%)',
    clipPath:
      'polygon(0 0, 100% 0, 100% 100%, calc(70% - 20px) 100%, calc(70% - 45px) 70%, 0 70%)',
  },
  polygon: {
    height: '60px',
    width: '100%',
    backgroundColor: 'rgba(28, 30, 33)',
    clipPath:
      'polygon(0 0, 100% 0, 100% calc(100% - 3px), calc(70% - 20px) calc(100% - 3px), calc(70% - 45px) calc(70% - 3px), 0 calc(70% - 3px))',
  },
  languageSwitch: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '25px',
  },
  languageButton: {
    margin: '10px',
    padding: '5px',
    textDecoration: 'none',
    color: 'white',
    fontFamily: 'Jura_600SemiBold',
    cursor: 'pointer',
  },
  btnSelected: {
    color: 'red',
  },
  btn: {
    color: 'white',
  },
  logoUgamiBoxDesktop: {
    display: 'flex',
    justifyContent: 'center',
    width: '99vw',
    position: 'absolute',
    zIndex: -1,
  },
  logoUgamiBoxMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '99vw',
    position: 'absolute',
    marginLeft: 10,
    zIndex: -1,
  },
  logoUgami: {
    height: 40,
    width: 135,
  },
});
