export const STATES: { [key: string]: string } = {
  Alabama: 'Alabama',
  Alaska: 'Alaska',
  'American Samoa': 'American Samoa',
  Arizona: 'Arizona',
  Arkansas: 'Arkansas',
  California: 'California',
  Colorado: 'Colorado',
  Connecticut: 'Connecticut',
  Delaware: 'Delaware',
  'District of Columbia': 'District of Columbia',
  'Federated States of Micronesia': 'Federated States of Micronesia',
  Florida: 'Florida',
  Georgia: 'Georgia',
  Guam: 'Guam',
  Hawaii: 'Hawaii',
  Idaho: 'Idaho',
  Illinois: 'Illinois',
  Indiana: 'Indiana',
  Iowa: 'Iowa',
  Kansas: 'Kansas',
  Kentucky: 'Kentucky',
  Louisiana: 'Louisiana',
  Maine: 'Maine',
  'Marshall Islands': 'Marshall Islands',
  Maryland: 'Maryland',
  Massachusetts: 'Massachusetts',
  Michigan: 'Michigan',
  Minnesota: 'Minnesota',
  Mississippi: 'Mississippi',
  Missouri: 'Missouri',
  Montana: 'Montana',
  Nebraska: 'Nebraska',
  Nevada: 'Nevada',
  'New Hampshire': 'New Hampshire',
  'New Jersey': 'New Jersey',
  'New Mexico': 'New Mexico',
  'New York': 'New York',
  'North Carolina': 'North Carolina',
  'North Dakota': 'North Dakota',
  'Northern Mariana Islands': 'Northern Mariana Islands',
  Ohio: 'Ohio',
  Oklahoma: 'Oklahoma',
  Oregon: 'Oregon',
  Palau: 'Palau',
  Pennsylvania: 'Pennsylvania',
  'Rhode Island': 'Rhode Island',
  'South Carolina': 'South Carolina',
  'South Dakota': 'South Dakota',
  Tennessee: 'Tennessee',
  Texas: 'Texas',
  Utah: 'Utah',
  Vermont: 'Vermont',
  'Virgin Islands': 'Virgin Islands',
  Virginia: 'Virginia',
  Washington: 'Washington',
  'West Virginia': 'West Virginia',
  Wisconsin: 'Wisconsin',
  Wyoming: 'Wyoming',
};

export const STATE_CODES: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FM: 'Federated States of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

// See: https://codigo-postal.co/en-us/usa/ and http://www.structnet.com/instructions/zip_min_max_by_state.html
export const isZipcodeValid = (state: string, _zipcode: string): boolean => {
  if (state === '' || state.length < 2 || _zipcode.length < 5) {
    return false;
  }

  const zipcode = parseInt(_zipcode, 10);

  if (state === 'Alabama' && zipcode >= 35004 && zipcode <= 36925) {
    return true;
  }
  if (state === 'Alaska' && zipcode >= 99501 && zipcode <= 99950) {
    return true;
  }
  if (state === 'Arizona' && zipcode >= 85001 && zipcode <= 86556) {
    return true;
  }
  if (
    (state === 'Arkansas' && zipcode >= 71601 && zipcode <= 72959) ||
    (state === 'Arkansas' && zipcode === 75502)
  ) {
    return true;
  }
  if (state === 'California' && zipcode >= 90001 && zipcode <= 96162) {
    return true;
  }
  if (state === 'Colorado' && zipcode >= 80001 && zipcode <= 81658) {
    return true;
  }
  if (
    (state === 'Connecticut' && zipcode >= 6001 && zipcode <= 6389) ||
    (state === 'Connecticut' && zipcode >= 6401 && zipcode <= 6928)
  ) {
    return true;
  }
  if (state === 'Delaware' && zipcode >= 19701 && zipcode <= 19980) {
    return true;
  }
  if (state === 'Florida' && zipcode >= 32003 && zipcode <= 34997) {
    return true;
  }
  if (
    (state === 'Georgia' && zipcode >= 30002 && zipcode <= 31999) ||
    (state === 'Georgia' && zipcode === 39901)
  ) {
    return true;
  }
  if (state === 'Hawaii' && zipcode >= 96701 && zipcode <= 96898) {
    return true;
  }
  if (state === 'Idaho' && zipcode >= 83201 && zipcode <= 83877) {
    return true;
  }
  if (state === 'Illinois' && zipcode >= 60001 && zipcode <= 62999) {
    return true;
  }
  if (state === 'Indiana' && zipcode >= 46001 && zipcode <= 47997) {
    return true;
  }
  if (state === 'Iowa' && zipcode >= 50001 && zipcode <= 52809) {
    return true;
  }
  if (state === 'Kansas' && zipcode >= 66002 && zipcode <= 67954) {
    return true;
  }
  if (state === 'Kentucky' && zipcode >= 40003 && zipcode <= 42788) {
    return true;
  }
  if (
    (state === 'Louisiana' && zipcode >= 70001 && zipcode <= 71232) ||
    (state === 'Louisiana' && zipcode >= 71234 && zipcode <= 71497)
  ) {
    return true;
  }
  if (state === 'Maine' && zipcode >= 3901 && zipcode <= 4992) {
    return true;
  }
  if (state === 'Maryland' && zipcode >= 20588 && zipcode <= 21930) {
    return true;
  }
  if (
    (state === 'Massachusetts' && zipcode >= 1001 && zipcode <= 2791) ||
    (state === 'Massachusetts' && zipcode >= 5501 && zipcode <= 5544)
  ) {
    return true;
  }
  if (state === 'Michigan' && zipcode >= 48001 && zipcode <= 49971) {
    return true;
  }
  if (state === 'Minnesota' && zipcode >= 55001 && zipcode <= 56763) {
    return true;
  }
  if (state === 'Mississippi' && zipcode >= 38601 && zipcode <= 39776) {
    return true;
  }
  if (state === 'Missouri' && zipcode >= 63001 && zipcode <= 65899) {
    return true;
  }
  if (state === 'Montana' && zipcode >= 59001 && zipcode <= 59937) {
    return true;
  }
  if (
    (state === 'Nebraska' && zipcode >= 68001 && zipcode <= 68118) ||
    (state === 'Nebraska' && zipcode >= 68122 && zipcode <= 69367)
  ) {
    return true;
  }
  if (state === 'Nevada' && zipcode >= 88901 && zipcode <= 89883) {
    return true;
  }
  if (state === 'New Hampshire' && zipcode >= 3031 && zipcode <= 3897) {
    return true;
  }
  if (state === 'New Jersey' && zipcode >= 7001 && zipcode <= 8989) {
    return true;
  }
  if (state === 'New Mexico' && zipcode >= 87001 && zipcode <= 88439) {
    return true;
  }
  if (state === 'New York' && zipcode >= 10001 && zipcode <= 14925) {
    return true;
  }
  if (state === 'North Carolina' && zipcode >= 27006 && zipcode <= 28909) {
    return true;
  }
  if (state === 'North Dakota' && zipcode >= 58001 && zipcode <= 58856) {
    return true;
  }
  if (state === 'Ohio' && zipcode >= 43001 && zipcode <= 45999) {
    return true;
  }
  if (
    (state === 'Oklahoma' && zipcode >= 73001 && zipcode <= 73199) ||
    (state === 'Oklahoma' && zipcode >= 73401 && zipcode <= 74966)
  ) {
    return true;
  }
  if (state === 'Oregon' && zipcode >= 97001 && zipcode <= 97920) {
    return true;
  }
  if (state === 'Pennsylvania' && zipcode >= 15001 && zipcode <= 19640) {
    return true;
  }
  if (state === 'Rhode Island' && zipcode >= 2801 && zipcode <= 2940) {
    return true;
  }
  if (state === 'South Carolina' && zipcode >= 29001 && zipcode <= 29945) {
    return true;
  }
  if (state === 'South Dakota' && zipcode >= 57001 && zipcode <= 57799) {
    return true;
  }
  if (state === 'Tennessee' && zipcode >= 37010 && zipcode <= 38589) {
    return true;
  }
  if (
    (state === 'Texas' && zipcode === 75001) ||
    (state === 'Texas' && zipcode >= 75503 && zipcode <= 79999)
  ) {
    return true;
  }
  if (state === 'Utah' && zipcode >= 84001 && zipcode <= 84791) {
    return true;
  }
  if (
    (state === 'Vermont' && zipcode >= 5001 && zipcode <= 5495) ||
    (state === 'Vermont' && zipcode >= 5601 && zipcode <= 5907)
  ) {
    return true;
  }
  if (state === 'Virginia' && zipcode >= 20101 && zipcode <= 24658) {
    return true;
  }
  if (state === 'Washington' && zipcode >= 98001 && zipcode <= 99403) {
    return true;
  }
  if (state === 'West Virginia' && zipcode >= 24701 && zipcode <= 26886) {
    return true;
  }
  if (state === 'Wisconsin' && zipcode >= 53001 && zipcode <= 54990) {
    return true;
  }
  if (state === 'Wyoming' && zipcode >= 82001 && zipcode <= 83414) {
    return true;
  }
  return false;
};
