import {
  useStyleSheet,
  StyleService,
  Input,
  CheckBox,
  Text,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback, View } from 'react-native';

import DropDownMenu from '../../components/Common/DropDownMenu';
import LabeledInput from '../../components/Common/LabeledInput';
import { STATES } from '../../constants/Address';
import { Props } from '../../types/AddressInputs';

const AddressInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    title,
    setTitle,
    street1,
    setStreet1,
    street2,
    setStreet2,
    city,
    setCity,
    state,
    setState,
    postalCode,
    setPostalCode,
    postalCodeError,
    setPostalCodeError,
    isPrimary = false,
    setIsPrimary,
    ugamiCardApp = false,
    verifyForm = false,
  } = props;
  const defaultCountry = 'United States';
  const styles = useStyleSheet(themedStyles);
  const street1Ref = useRef<Input>(null);
  const street2Ref = useRef<Input>(null);
  const cityRef = useRef<Input>(null);

  const onSubmitEditingTitle = useCallback(() => {
    street1Ref.current?.focus();
  }, []);

  const onSubmitEditingStreet2 = useCallback(() => {
    cityRef.current?.focus();
  }, []);

  const onChangePostalCode = (value) => {
    const zipCode = value.replace(/[^0-9•]/g, '');
    setPostalCode(zipCode);
  };

  useEffect(() => {
    if (postalCode) {
      if (postalCode.length === 5) {
        setPostalCodeError('');
      } else {
        setPostalCodeError('invalid zipcode');
      }
    } else {
      setPostalCodeError('');
    }
  }, [postalCode]);

  return (
    <>
      {!ugamiCardApp && (
        <LabeledInput
          label={t<string>(
            'settings.personal_info.address_form.address_nickname',
          )}
          placeholder={t<string>(
            'settings.personal_info.address_form.address_nickname_placeholder',
          )}
          autoCorrect={false}
          autoCapitalize="words"
          returnKeyType="next"
          onChangeText={setTitle}
          defaultValue={title}
          onSubmitEditing={onSubmitEditingTitle}
          testID="AddressTitleInput"
          disabled={verifyForm}
        />
      )}
      {verifyForm ? (
        <LabeledInput
          label={t<string>('settings.personal_info.address_form.address_1')}
          defaultValue={street1}
          disabled
          testID="Street1Input"
        />
      ) : (
        <LabeledInput
          label={t<string>('settings.personal_info.address_form.address_1')}
          placeholder={t<string>(
            'settings.personal_info.address_form.placeholder_1',
          )}
          autoCapitalize="words"
          returnKeyType="next"
          onChangeText={setStreet1}
          defaultValue={street1}
          disabled={verifyForm}
        />
      )}
      {verifyForm && !street2 ? (
        <></>
      ) : (
        <LabeledInput
          ref={street2Ref}
          label={t<string>('settings.personal_info.address_form.address_2')}
          placeholder={t<string>(
            'settings.personal_info.address_form.placeholder_2',
          )}
          autoCapitalize="words"
          autoCorrect={false}
          returnKeyType="next"
          onChangeText={setStreet2}
          defaultValue={street2}
          onSubmitEditing={onSubmitEditingStreet2}
          testID="Street2Input"
          disabled={verifyForm}
        />
      )}
      <LabeledInput
        ref={cityRef}
        label={t<string>('settings.personal_info.address_form.city')}
        placeholder={t<string>('settings.personal_info.address_form.city')}
        autoCapitalize="words"
        autoCorrect={false}
        returnKeyType="next"
        onChangeText={setCity}
        defaultValue={city}
        testID="CityInput"
        disabled={verifyForm}
      />
      <View style={styles.row}>
        <View style={styles.state}>
          {verifyForm ? (
            <LabeledInput
              label={t<string>('settings.personal_info.address_form.state')}
              defaultValue={state}
              disabled
            />
          ) : (
            <DropDownMenu
              label={t<string>('settings.personal_info.address_form.state')}
              placeholder={t<string>(
                'settings.personal_info.address_form.select_state',
              )}
              data={STATES}
              value={state || ''}
              onSelect={setState}
            />
          )}
        </View>
        <View style={styles.postal}>
          <LabeledInput
            label={t<string>('settings.personal_info.address_form.zip_code')}
            value={postalCode}
            placeholder="00000"
            autoCapitalize="none"
            keyboardType="number-pad"
            autoCorrect={false}
            returnKeyType="next"
            onChangeText={(value) => onChangePostalCode(value)}
            defaultValue={postalCode?.toString()}
            maxLength={5}
            testID="ZipCodeInput"
            disabled={verifyForm}
            error={postalCodeError ? { message: postalCodeError } : undefined}
          />
        </View>
      </View>
      <LabeledInput
        label={t<string>('settings.personal_info.address_form.country')}
        value={defaultCountry}
        disabled
      />
      {!ugamiCardApp && setIsPrimary && (
        <TouchableWithoutFeedback
          onPress={() => setIsPrimary(!isPrimary)}
          testID="PrimaryBillingAddressTouchable"
        >
          <View style={styles.checkboxRow}>
            <CheckBox
              status="basic"
              checked={isPrimary}
              onChange={(nextChecked) => setIsPrimary(nextChecked)}
              disabled={verifyForm}
            />
            <Text style={styles.checkboxText}>
              {t<string>('settings.personal_info.address_form.is_primary')}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  row: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  state: {
    flex: 1,
    marginRight: 8,
  },
  postal: {
    flex: 1,
    marginLeft: 8,
  },
  checkboxRow: {
    flexDirection: 'row',
    marginTop: 36,
    alignItems: 'center',
  },
  checkboxText: {
    flex: 1,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_400Regular',
  },
});

export default observer(AddressInput);
